import React, { useState } from 'react'
import { getFullImageUrl } from 'utils'
import { Box, Typography, Collapse } from '@mui/material'
import { Avatar, Loader } from 'packages/eid-ui'
import DelegationArrow from './DelegationArrow'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import IconContainer from './IconContainer'
import { Icon } from 'packages/eid-icons'
import { ShowHideButton } from 'components'
import { Container } from 'components/DetailsComponents'
import NoDataFoundMessage from 'packages/eid-ui/NoDataFoundMessage'

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
        lineHeight: 1.14,
    },
    subtext: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        marginTop: '4px',
    },
}

const PersonDetails = ({
    imgSrc,
    name,
    createdDateTime,
    subtext,
    size = 'large',
}) => (
    <Box width="220px" position="relative" display="flex" alignItems="center">
        <Box marginY="8px" display="flex" alignItems="center">
            <Box marginRight="10px">
                <Avatar size={size} src={imgSrc} />
            </Box>
            <Box>
                <Typography style={styles.name}>{name}</Typography>

                {subtext && (
                    <Box display="flex" alignItems="center">
                        <Typography style={styles.subtext}>
                            {subtext}
                        </Typography>
                    </Box>
                )}
                {createdDateTime && (
                    <Box
                        position="relative"
                        marginTop="4px"
                        display="flex"
                        alignItems="center"
                    >
                        <Icon
                            height="16px"
                            width="16px"
                            name="Clock"
                            color="#5d6870"
                        />
                        <Box marginLeft="4px" color="#5d6870" fontSize="13px">
                            {moment.utc(createdDateTime).local().format('LLL')}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    </Box>
)

const AssignTaskLog = ({ data }) => {
    const { t } = useTranslation()
    return (
        <Box display="flex" justifyContent="center" my={3}>
            <PersonDetails
                size="large"
                name={data.actorPersonFriendlyName}
                imgSrc={getFullImageUrl(data.actorPersonImageThumbUrl)}
                subtext={t('Delegator')}
                createdDateTime={data.createdDateUtc}
            />
            <DelegationArrow
                stepFriendlyName={data.stepFriendlyName}
                actionFriendlyName={t('AssignedTask')}
                createdDateTime={data.createdDateUtc}
                style={{ margin: '0px 24px 0px 0px' }}
            />
            <PersonDetails
                size="large"
                name={data.targetPersonFriendlyName}
                imgSrc={getFullImageUrl(data.targetPersonImageThumbUrl)}
                subtext={t('Delegatee')}
            />
        </Box>
    )
}

const AddAdditionalApproverLog = ({ data }) => {
    const { t } = useTranslation()
    return (
        <Box display="flex" justifyContent="center" my={3}>
            <PersonDetails
                size="large"
                name={data.actorPersonFriendlyName}
                imgSrc={getFullImageUrl(data.actorPersonImageThumbUrl)}
                createdDateTime={data.createdDateUtc}
            />
            <DelegationArrow
                stepFriendlyName={data.stepFriendlyName}
                actionFriendlyName={t('AddedAsApprover')}
                createdDateTime={data.createdDateUtc}
                style={{ margin: '0px 24px 0px 0px' }}
            />
            <PersonDetails
                size="large"
                name={data.targetPersonFriendlyName}
                imgSrc={getFullImageUrl(data.targetPersonImageThumbUrl)}
            />
        </Box>
    )
}

const ClaimLog = ({ data }) => {
    const { t } = useTranslation()
    return (
        <Box display="flex" justifyContent="center" my={3}>
            <PersonDetails
                size="large"
                name={data.targetPersonFriendlyName}
                imgSrc={getFullImageUrl(data.targetPersonImageThumbUrl)}
                createdDateTime={data.createdDateUtc}
            />
            <DelegationArrow
                stepFriendlyName={data.stepFriendlyName}
                actionFriendlyName={t('Claimed')}
                createdDateTime={data.createdDateUtc}
                style={{ margin: '0px 24px 0px 0px' }}
            />
            <PersonDetails
                size="large"
                name={data.targetPersonFriendlyName}
                imgSrc={getFullImageUrl(data.targetPersonImageThumbUrl)}
            />
        </Box>
    )
}

const UnclaimLog = ({ data }) => {
    const { t } = useTranslation()
    const isUnassignment = data.actorPersonId !== data.targetPersonId
    return (
        <Box display="flex" justifyContent="center" my={3}>
            <PersonDetails
                size="large"
                name={data.actorPersonFriendlyName}
                imgSrc={getFullImageUrl(data.actorPersonImageThumbUrl)}
                createdDateTime={data.createdDateUtc}
            />
            <DelegationArrow
                stepFriendlyName={data.stepFriendlyName}
                actionFriendlyName={
                    isUnassignment
                        ? t('Unassigned')
                        : t('Unclaimed')
                }
                createdDateTime={data.createdDateUtc}
                style={{ margin: '0px 24px 0px 0px' }}
            />
            {isUnassignment ? (
                <PersonDetails
                    size="large"
                    name={data.targetPersonFriendlyName}
                    imgSrc={getFullImageUrl(data.targetPersonImageThumbUrl)}
                />
            ) : (
                <PersonDetails
                    size="large"
                    name={data.actorPersonFriendlyName}
                    imgSrc={getFullImageUrl(data.actorPersonImageThumbUrl)}
                />
            )}
        </Box>
    )
}

const componentsMap = {
    'ACT-Person-Approval-SetAsApprover': AssignTaskLog,
    'ACT-Person-Approval-AddAsApprover': AddAdditionalApproverLog,
    'ACT-Person-Approval-UnClaimStep': UnclaimLog,
    'ACT-Person-Approval-ClaimStep': ClaimLog,
}

const DelegationsList = ({ data }) => {
    const { t } = useTranslation()
    return (
        <>
            {data.length === 0 && (
                <Box margin="24px" display="flex" justifyContent="center">
                    {t('NoDelegationsDone')}
                </Box>
            )}
            {data.map((md, index) => {
                const Component = componentsMap[md.logType]
                return (
                    <Component
                        key={`${md.actorPersonId}_${md.targetPersonId}_${index}`}
                        data={md}
                    />
                )
            })}
        </>
    )
}

const iconStyles = {
    iconContainer: {
        backgroundImage: 'linear-gradient(135deg, #afbcc6, #668397)',
    },
    icon: {
        color: '#ffffff',
        width: '20px',
        height: '20px',
    },
}

const OneItemDelegations = ({ data }) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <Box
                paddingLeft="24px"
                paddingRight="45px"
                paddingY="12px"
                display="flex"
                alignItems="center"
                borderBottom="solid 1px #d8dadd"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <IconContainer style={iconStyles.iconContainer}>
                        <Icon
                            name={data.resourceType}
                            style={iconStyles.icon}
                        />
                    </IconContainer>

                    <Typography
                        style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            marginLeft: '24px',
                            color: '#d66a00',
                        }}
                    >
                        {data.requestedResourcePreviewName}
                    </Typography>
                </Box>

                <ShowHideButton
                    expanded={expanded}
                    onClick={() => setExpanded((prev) => !prev)}
                    withLabel={false}
                />
            </Box>
            <Collapse in={expanded}>
                {expanded && <DelegationsList data={data.delegations} />}
            </Collapse>
        </>
    )
}

const DelegationsHistory = ({ useDataHook, requestId, itemId, page }) => {
    const { t } = useTranslation()

    const { data, isLoading } = useDataHook(requestId, itemId)

    if (isLoading) return <Loader />

    if (page === 'Request') {
        return (
            <Container>
                <Box width="100%">
                    {data?.delegations?.length === 0 && data.items.length === 0 &&
                        <Box
                            padding="15px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <NoDataFoundMessage message={t('NoDelegationsDone')} />
                        </Box>}
                    {data?.delegations?.length > 0 && (
                        <>
                            <Box>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: '#000000',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {t('RequestDelegations')}
                                </Typography>
                            </Box>
                            <DelegationsList data={data.delegations} />
                        </>
                    )}
                    {data.items.length > 0 &&
                        data.items.some((i) => i.delegations.length > 0) && (
                            <>
                                <Box my={3} mx={3}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#000000',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {t('ItemDelegations')}
                                    </Typography>
                                </Box>
                                {data.items.map(
                                    (i) =>
                                        i?.delegations?.length > 0 && (
                                            <OneItemDelegations
                                                data={i}
                                                key={i.businessRequestItemId}
                                            />
                                        ),
                                )}
                            </>
                        )}
                </Box>
            </Container>
        )
    } else {
        return (
            <Container>
                <Box maxWidth="1000px" minWidth="1000px">
                    <DelegationsList
                        data={
                            data.items.length > 0
                                ? data.items[0].delegations
                                : []
                        }
                    />
                </Box>
            </Container>
        )
    }
}

export default DelegationsHistory
