import React from 'react'
import { usePageContext } from 'pageContext'
import { PersonFilter } from 'components'
import { getURLTarget, useAuthState } from 'packages/core'
import { useTranslation } from 'react-i18next'
import {
    AttributeContainer,
    AttributeValue,
    getLocationString,
    styles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import { Avatar as MuiAvatar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { getFullImageUrl } from 'utils'
import useSubcomponents from 'useSubcomponents'

export const Filter = () => {
    const [state, dispatch]: any = usePageContext()
    const [{ currentUserId, friendlyName }]: any = useAuthState()

    return (
        <PersonFilter
            value={state.subject}
            onClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'subject',
                        value: {
                            id: currentUserId,
                            friendlyName: friendlyName,
                        },
                    },
                })
            }
            onChange={(payload: any) => {
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'subject',
                        value: payload,
                    },
                })
            }}
        />
    )
}

const useStyles = makeStyles({
    avatarContainer: {
        position: 'absolute',
        right: '15px',
    },
    avatar: {
        width: '100px',
        height: '100px',
        boxShadow: 'rgb(110 110 111 / 10%) 0px 20px 15px 3px',
    },
    viewProfileAnchor: {
        padding: '0px 0px 0px 13px',
        textDecoration: 'none',
        color: 'rgb(48, 127, 193)',
        '&:hover,&:active,&:visted': {
            color: 'rgb(48, 127, 193)',
        },
    },
    infoBox: {
        '& div': {
            '& div': {
                marginRight: '10px',
                fontSize: '10px',
            },
        },
    },
})

export const Details = ({ accessRecipient }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { canSeeEIDProfileLink } = useSubcomponents()

    const { BASE_EID_URL } = (window as any).config
    return (
        <>
            <Box
                className={classes.avatarContainer}
                marginBottom="24px !important"
            >
                <MuiAvatar
                    src={getFullImageUrl(accessRecipient.personPhotoUrl)}
                    className={classes.avatar}
                />
            </Box>
            <Box className={classes.infoBox}>
                <AttributeContainer style={{ display: 'block' }}>
                    <VerticalAlignedLabel>
                        {t('AccessRecipient')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={accessRecipient.friendlyName || '-'}
                        style={styles.roleReceiverValue}
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('JobFunction')}
                    </VerticalAlignedLabel>
                    <AttributeValue
                        value={accessRecipient.jobTitle}
                        style={styles.nextToAvatar}
                        noWrap
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('Location')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        style={styles.nextToAvatar}
                        value={getLocationString(
                            accessRecipient.city,
                            accessRecipient.country,
                        )}
                        noWrap
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('Manager')}
                    </VerticalAlignedLabel>
                    <AttributeValue
                        value={accessRecipient.managerFriendlyName}
                        noWrap
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('Email')}
                    </VerticalAlignedLabel>
                    <AttributeValue value={accessRecipient.email} noWrap />
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('Login')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={accessRecipient.login}
                        noWrap
                        style={styles.locationValue}
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('EmployeeId')}
                    </VerticalAlignedLabel>
                    <AttributeValue value={accessRecipient.employeeId} noWrap />
                </AttributeContainer>

                {canSeeEIDProfileLink && (
                    <AttributeContainer
                        style={{ marginTop: 15 }}
                        data-protectedsubcomponent={canSeeEIDProfileLink}
                    >
                        <a
                            className={classes.viewProfileAnchor}
                            target={getURLTarget()}
                            href={BASE_EID_URL + accessRecipient.eidPersonUrl}
                        >
                            <Icon
                                name="ViewProfile"
                                style={{ marginRight: '10px' }}
                            />
                            {t('ViewUserProfile')}
                        </a>
                    </AttributeContainer>
                )}
            </Box>
        </>
    )
}
