import { Box } from '@mui/material'
import AccessRequestPolicyDetails from 'components/AccessRequestPolicyDetails'
import { EndDateTimePicker } from 'components/TimeConstrainedControls/EndDateTimePicker'
import { StartDateTimePicker } from 'components/TimeConstrainedControls/StartDateTimePicker'
import { useAccessRequestPolicy } from 'hooks'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { ToggleSwitch } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isDateInPast } from 'utils'

const DecisionTimeConstraintControl = ({
    requestPolicyId,
    selectedStartDate,
    selectedEndDate,
    handleStartDate,
    handleEndDate,
    timeConstraintRequired = false,
}) => {
    const { t } = useTranslation()
    const policy = useAccessRequestPolicy(requestPolicyId)

    const [toggleValue, setToggle] = useState(
        Boolean(selectedEndDate) ||
            policy?.isTimeConstrained ||
            timeConstraintRequired,
    )

    const handleToggle = () => {
        if (toggleValue) {
            handleEndDate(null)
        }
        setToggle((prev) => !prev)
    }

    const isInvalidDate = moment(selectedStartDate).isAfter(
        moment(selectedEndDate),
    )

    return (
        <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'}>
                <Box minWidth="14rem">
                    <ToggleSwitch
                        value={toggleValue}
                        label={t('SetDuration')}
                        labelColor="#5d6870"
                        disabled={
                            policy?.isTimeConstrained || timeConstraintRequired
                        }
                        onChange={handleToggle}
                    />
                </Box>
                {toggleValue && (
                    <>
                        <Box minWidth={'20rem'}>
                            <Box padding={'0 0.4rem'} display="flex">
                                <Box
                                    style={{
                                        alignSelf: 'end',
                                        marginRight: '0.8rem',
                                    }}
                                >
                                    <Icon
                                        name={'StartTime'}
                                        style={{
                                            marginLeft: '2rem',
                                        }}
                                    />
                                </Box>
                                <StartDateTimePicker
                                    selectedStartDate={selectedStartDate}
                                    setSelectedStartDate={handleStartDate}
                                    showWarningColor={
                                        isInvalidDate ||
                                        isDateInPast(selectedEndDate)
                                    }
                                />
                            </Box>

                            <Box padding={'0 0.4rem'} display="flex">
                                <Box
                                    style={{
                                        alignSelf: 'end',
                                        marginRight: '0.8rem',
                                    }}
                                >
                                    <Icon
                                        name={'EndTime'}
                                        style={{
                                            marginLeft: '2rem',
                                        }}
                                    />
                                </Box>
                                <EndDateTimePicker
                                    selectedEndDate={selectedEndDate}
                                    setSelectedEndDate={handleEndDate}
                                    maxEndDate={
                                        policy?.isTimeConstrained
                                            ? moment(selectedStartDate).add(
                                                  policy?.maximumValueInMinutes,
                                                  'minute',
                                              )
                                            : null
                                    }
                                    showWarningColor={
                                        isInvalidDate ||
                                        isDateInPast(selectedEndDate)
                                    }
                                    // hasInvalidDates={hasInvalidDates}
                                    // validationMessage={invalidDatesMessage}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            {toggleValue && policy && (
                <Box width={'60%'}>
                    <AccessRequestPolicyDetails
                        policy={policy}
                        orientation="vertical"
                        noOfColumns={2}
                    />
                </Box>
            )}
        </Box>
    )
}

export default DecisionTimeConstraintControl
