import { TextField, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'

export const StartDateTimePicker = ({
    selectedStartDate,
    setSelectedStartDate,
    showWarningColor = false,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <NativeDateTimePicker
            minDate={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            value={moment.utc(selectedStartDate)
                .clone()
                .local()
                .format('YYYY-MM-DDTHH:mm')}
            handleChange={(e) => {
                if (isNilOrEmpty(e)) {
                    setSelectedStartDate(moment().utc().format())
                } else {
                    const date = moment(e)
                    const dateToSet = date.clone().utc().format()
                    setSelectedStartDate(dateToSet)
                }
            }}
            pickerStyles={{ top: '22px', right: '10px', width: 'auto' }}
            customTimeControl={false}
            disabledTimeSlots={[]}
            clearBtnLabel={t('Clear')}
            todayBtnLabel={t('Today')}
        >
            <TextField
                type="text"
                value={
                    selectedStartDate
                        ? moment.utc(selectedStartDate).local().format('L LT')
                        : ''
                }
                label={t('StartDateTime')}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    style: {
                        color: showWarningColor
                            ? theme?.palette?.warning?.main
                            : 'black',
                    },
                }}
            />
        </NativeDateTimePicker>
    )
}
