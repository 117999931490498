import { Box, TextField, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'

const useStyles = makeStyles((theme) =>
    createStyles({
        policyViolationMessage: {
            position: 'absolute',
            fontSize: '12px',
            color: theme.palette.error.main,
            marginRight: '12px',
            wordBreak: 'break-word',
        },
    }),
)

export const EndDateTimePicker = ({
    selectedEndDate,
    setSelectedEndDate,
    minEndDate,
    maxEndDate,
    hasInvalidDates = false,
    validationMessage = '',
    showWarningColor = false,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    return (
        <>
            <NativeDateTimePicker
                minDate={
                    minEndDate
                        ? minEndDate.format('YYYY-MM-DDTHH:mm')
                        : moment(new Date()).format('YYYY-MM-DDTHH:mm')
                }
                maxDate={maxEndDate?.format('YYYY-MM-DDTHH:mm')}
                value={moment
                    .utc(selectedEndDate)
                    .clone()
                    .local()
                    .format('YYYY-MM-DDTHH:mm')}
                handleChange={(e) => {
                    if (isNilOrEmpty(e)) {
                        if (maxEndDate) {
                            setSelectedEndDate(maxEndDate.utc().format())
                        } else setSelectedEndDate(moment().utc().format())
                    } else {
                        const date = moment(e)
                        const dateToSet = date.clone().utc().format()
                        setSelectedEndDate(dateToSet)
                    }
                }}
                pickerStyles={{ top: '22px', right: '10px', width: 'auto' }}
                customTimeControl={false}
                disabledTimeSlots={[]}
                clearBtnLabel={t('Clear')}
                todayBtnLabel={t('Today')}
            >
                <TextField
                    type="text"
                    value={
                        selectedEndDate
                            ? moment.utc(selectedEndDate).local().format('L LT')
                            : ''
                    }
                    label={t('EndDateTime')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        style: {
                            color: showWarningColor
                                ? theme?.palette?.warning?.main
                                : 'black',
                        },
                    }}
                />
            </NativeDateTimePicker>
            {hasInvalidDates && validationMessage && (
                <Box className={classes.policyViolationMessage}>
                    {validationMessage}
                </Box>
            )}
        </>
    )
}
