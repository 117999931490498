import { Box, Grid, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ACTION_TYPES, FIELD_TYPE_COMPONENTS_TYPES } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { Divider, StatusIndicator, Tooltip } from 'packages/eid-ui'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    getCombinedFields,
    getFieldTypeWithValue,
    updateJsonMappingWithApiFormat,
} from 'utils'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerWrapper: {
        display: 'flex',
        width: '90%',
        borderBottom: '0.1rem solid #efeff1',
        alignItems: 'center',
        paddingBottom: '0.5rem',
    },
    feildHead: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        width: '100%',
        marginRight: '1.6rem',
        lineHeight: '3.4rem',
        maxWidth: '20rem',
        '& label': {
            fontSize: '1.6rem',
            fontWeight: '500',
        },
    },
    rootWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        padding: '1.2rem 0',
    },

    fieldName: {
        maxWidth: '20rem',
        width: '100%',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#9b9b9b',
        textTransform: 'uppercase',
    },
    fieldNameCardView: {
        alignSelf: 'flex-start',
    },
    fieldData: {
        fontSize: '1.4rem',
        color: '#000000',
        fontWeight: 'normal',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    dataRange: {
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        '& label': {
            fontSize: '1.2rem',
            fontWeight: 'bold ',
            color: `${theme.palette.grey[500]} !important`,
            marginRight: '0.8rem',
            textTransform: 'uppercase',
        },
    },
    additionalFieldWrapper: {
        padding: '0 1.6rem',
    },
    fieldHeaderTitle: {
        display: 'flex',
        justifyContent: 'space-between',

        boxShadow: '0 0.4rem 1.7rem 0 rgba(0, 0, 0, 0.07)',
        padding: '1.7rem 1.6rem 1.2rem !important',
        background: `linear-gradient(90deg, rgba(246,246,248,1) 0%, rgba(244,244,246,1) 0%, rgba(238,103,35,1) 0%, rgba(23,154,170,0.95) 0%, rgba(23,129,188,0.95) 100%),repeating-linear-gradient(${theme?.palette?.common?.white}, ${theme?.palette?.common?.white} 3px, rgba(0, 0, 0, 0.02) 3px, rgba(0, 0, 0, 1.02) 4px)`,
        borderTop: 'solid 0.1rem #e8e8e8 !important',
        borderBottom: 'solid 0.1rem #e8e8e8 !important',
    },
    expandButton: {
        textTransform: 'uppercase',
        zIndex: 9999,
        marginTop: '-0.4rem',
        color: theme?.palette?.common?.white,
        fontSize: '1.2rem',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    expandIcon: {
        width: '1.6rem',
        height: '1.6rem',
        marginLeft: '0.8rem',
        marginTop: '-0.1rem',
        color: theme?.palette?.common?.white,
    },
    cartItemContentWithFieldTypes: {
        paddingBottom: '0 !important',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        '& div[class*="-cartCommentContainer"]': {
            padding: '0 1.6rem',
        },
    },
    fieldTypeAttribute: {
        padding: '0rem',
        '& div': {
            fontSize: '1.4rem',
            fontWeight: '600',
            color: theme?.palette?.common?.white,
        },
    },
    noDataFound: {
        width: '100%',
        textAlign: 'center',
        marginBottom: '2rem',
    },
    containerWrapperMain: {
        display: 'flex',
        width: '90%',
        borderBottom: '0.1rem solid #efeff1',
        alignItems: 'center',
        paddingBottom: '0.5rem',
    },
    containerWrapperCardView: {
        width: '100%',
        justifyContent: 'space-between',
    },
    fieldCardHeaderTitle: {
        borderTop: '0.1rem solid rgb(216, 218, 221) !important',
        borderBottom: '0.1rem solid rgb(216, 218, 221) !important',
    },
    rootWrapperCardView: {
        flexDirection: 'column',
        alignItems: 'flex-start !important',
        gap: '1rem !important',
    },
    modifiedFieldLabel: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: '1',
    },
    modifiedFieldLabelCardView: {
        alignSelf: 'flex-end',
    },
    fieldDataRange: {
        display: 'block',
    },
    multiselectContainer: {
        '& label': {
            color: '#9e9e9e',
            fontSize: '1.2rem',
            textTransform: 'uppercase',
            fontWeight: '600',
        },
        '& p': {
            color: '#3b454d',
            fontSize: '1.3rem',
        },
    },
    matchingLabel: {
        color: '#767676',
        fontWeight: 'normal',
        fontSize: '1rem',
        textTransform: 'uppercase',
        marginTop: '0.2rem',
    },
    matchingLabelValue: {
        color: theme?.palette?.black,
        fontWeight: 'normal',
        fontSize: '1.3rem',
    },
    fieldScopeName: {
        fontSize: '1rem !important',
        marginLeft: '.4rem',
        textTransform: 'none',
    },
    freeTextMultiValue: {
        display: 'flex',
        gap: '2rem',
        width: '100%',
        marginBottom: '.6rem',
        '& label': {
            fontSize: '1rem',
            fontWeight: 'bold ',
            color: `${theme.palette.grey[500]} !important`,
            marginRight: '0.8rem',
            textTransform: 'uppercase',
        },
    },
}))

const Attribute = ({ label, value, ...rest }) => {
    const theme = useTheme()

    return (
        <Box {...rest}>
            <Box
                color={theme?.palette?.grey?.[900]}
                fontSize="1.2rem"
                style={{ textTransform: 'uppercase' }}
            >
                {label}
            </Box>
        </Box>
    )
}

const RequestDynamicFieldTypeValues = ({ cardView = false, fieldTypeJson }) => {
    const { t } = useTranslation()
    const [showMore, setShowMore] = useState(true)
    const [fieldTypeValueJson, setFieldTypeJson] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        if (fieldTypeJson) {
            const dataObject = JSON.parse(fieldTypeJson)
            const parsedData = Array.isArray(dataObject)
                ? dataObject[0]
                : dataObject
            setFieldTypeJson(updateJsonMappingWithApiFormat(parsedData, false))
        }
    }, [fieldTypeJson])

    const bindFieldTypes = () => {
        const modifiedField = getCombinedFields(fieldTypeValueJson)
        return (
            <Fragment>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    {modifiedField?.map((field, index) => (
                        <DynamicFieldAdditionalLabelValue
                            className={classNames(
                                classes.additionalField,
                                classes.additionalFieldWrapper,
                            )}
                            item={field}
                            isLastItem={index === modifiedField.length - 1}
                            key={`field-type-${index}`}
                            cardView={cardView}
                        />
                    ))}
                </Grid>
            </Fragment>
        )
    }

    return (
        <>
            <Fragment>
                <Box>
                    <Box
                        className={classNames(classes.fieldHeaderTitle, {
                            [classes.fieldCardHeaderTitle]: cardView,
                        })}
                    >
                        <Attribute
                            padding={'1.6rem 0'}
                            className={classNames(classes.fieldTypeAttribute)}
                            label={t('Common_SelectedFieldTypeValues')}
                        />

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            fontSize="1.2rem"
                            fontWeight="bold"
                        >
                            <Box
                                component="span"
                                display="flex"
                                className={classes.expandButton}
                                onClick={() => setShowMore(!showMore)}
                            >
                                {showMore ? t('Hide') : t('Show')}
                                <Box className={classes.expandIcon}>
                                    {showMore ? (
                                        <Icon
                                            name="MinusCircle"
                                            width="1.6rem"
                                            height="1.6rem"
                                        />
                                    ) : (
                                        <Icon
                                            name="PlusCircle"
                                            width="1.6rem"
                                            height="1.6rem"
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {showMore && (
                        <Box display="flex">
                            <Grid
                                style={{
                                    boxShadow:
                                        '0 4px 16px 0 rgba(0, 0, 0, 0.11)',
                                    paddingTop: '1.2rem',
                                }}
                                container
                                spacing={1}
                            >
                                {bindFieldTypes()}
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Fragment>
        </>
    )
}

const DynamicFieldAdditionalLabelValue = ({
    item,
    isLastItem,
    cardView,
    ...rest
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const fieldTypeswithValue = []
    item?.fieldTypes?.forEach((field) => {
        const validField = getFieldTypeWithValue(field)
        if (validField) {
            fieldTypeswithValue.push(validField)
        }
    })

    const bindArrayValues = (assignedValues) => {
        const addedValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.ADDED,
        )
        const deletedValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.DELETED,
        )

        const updatedValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.UPDATED,
        )

        const noChangeValues = assignedValues.filter(
            (x) =>
                x.ActionType === ACTION_TYPES.NO_CHANGE || x.ActionType === '',
        )

        const addedValuesLabels = addedValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        const removedValuesLabels = deletedValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        const updatedValuesLabels = updatedValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        const noChangeValuesLabels = noChangeValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        return (
            <Box className={classes.multiselectContainer}>
                {bindValueName(
                    'Common_Added',
                    addedValuesLabels,
                    removedValuesLabels?.length ||
                        updatedValuesLabels?.length ||
                        noChangeValuesLabels?.length,
                )}
                {bindValueName(
                    'Common_Deleted',
                    removedValuesLabels,
                    addedValuesLabels?.length ||
                        updatedValuesLabels?.length ||
                        noChangeValuesLabels?.length,
                )}
                {bindValueName(
                    'Common_Updated',
                    updatedValuesLabels,
                    removedValuesLabels?.length ||
                        addedValuesLabels?.length ||
                        noChangeValuesLabels?.length,
                )}
                {bindValueName(
                    'Unchanged',
                    noChangeValuesLabels,
                    removedValuesLabels?.length ||
                        updatedValuesLabels?.length ||
                        addedValuesLabels?.length,
                )}
            </Box>
        )
    }

    const bindValueName = (labelKey, values, showLabel) => {
        if (values?.length) {
            return (
                <Box
                    display="flex"
                    style={{
                        gap: '1rem',
                        marginTop: '1rem',
                    }}
                >
                    {showLabel ? <label>{`${t(labelKey)}: `}</label> : null}
                    <Typography> {values.join(', ')}</Typography>
                </Box>
            )
        }
        return null
    }

    const getFieldValueType = (field) => {
        try {
            return field.AzFieldTypeSelectionRuleTypeID === 3
                ? 'AssignedValues'
                : 'AssignedValue'
        } catch (err) {}
    }

    const isFieldModified = (field) => {
        return field?.isModified || field?.ActionType === 'Fixed' || false
    }

    const getChangeLabel = (field) => {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID === 3
                ? 'AssignedValues'
                : 'AssignedValue'
        const fieldValue = field[valueType]
        if (Array.isArray(fieldValue) && fieldValue?.length) {
            const isAdded = fieldValue.find((x) => x.ActionType === 'Added')
            const isDeleted = fieldValue.find((x) => x.ActionType === 'Deleted')
            const isFixed = fieldValue.find((x) => x.ActionType === 'Fixed')
            const isUpdated = fieldValue.find((x) => x.ActionType === 'Updated')
            const isModified = (isAdded && isDeleted) || isUpdated
            if (isModified) {
                return 'Common_Updated'
            }
            return isAdded
                ? 'Common_Added'
                : isDeleted
                ? 'Common_Deleted'
                : isFixed
                ? 'Common_Fixed'
                : ''
        }
        return `Common_${field?.ActionType}`
    }

    const getChangeLabelVariant = (field) => {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID === 3
                ? 'AssignedValues'
                : 'AssignedValue'
        const fieldValue = field[valueType]
        if (Array.isArray(fieldValue) && fieldValue?.length) {
            const isAdded = fieldValue.find((x) => x.ActionType === 'Added')
            const isDeleted = fieldValue.find((x) => x.ActionType === 'Deleted')
            const isFixed = fieldValue.find((x) => x.ActionType === 'Fixed')
            const isUpdated = fieldValue.find((x) => x.ActionType === 'Updated')
            const isModified = (isAdded && isDeleted) || isUpdated
            if (isModified || isFixed) {
                return 'Open_PendingApproval'
            }
            return isAdded
                ? 'Approved'
                : isDeleted
                ? 'Rejected'
                : isFixed
                ? 'Fixed'
                : ''
        }

        return field?.ActionType === 'Added'
            ? 'Approved'
            : field?.ActionType === 'Updated' || field?.ActionType === 'Fixed'
            ? 'Open_PendingApproval'
            : 'Rejected'
    }

    if (!fieldTypeswithValue?.length) {
        return null
    }

    const bindRangeValue = (field) => {
        const fromVal =
            field?.ActionType === 'Deleted'
                ? field?.oldValue?.oldFrom
                : field?.AssignedValueFrom
        const toValue =
            field?.ActionType === 'Deleted'
                ? field?.oldValue?.oldTo
                : field?.AssignedValueTo

        return (
            <Fragment>
                <Box className={classes.dataRange}>
                    <Box color="#3b454d" fontSize="1.3rem">
                        <label>{`${t('Common_From')}:`}</label> {fromVal}
                    </Box>
                    <Box color="#3b454d" fontSize="1.3rem">
                        <label>{`${t('Common_To')}:`}</label> {toValue}
                    </Box>
                </Box>
            </Fragment>
        )
    }

    const bindFixedValue = (field) => {
        try {
            if (field?.ActionType === 'Deleted') {
                return field?.oldValue?.oldValue
            }
            return field?.[getFieldValueType(field)]?.ValueName
        } catch (err) {}
    }

    const bindFreeTextMultiValue = (assignedValue) => {
        if (!assignedValue?.length) {
            return null
        }

        const addedValues = assignedValue.filter(
            (x) => x.ActionType === ACTION_TYPES.ADDED,
        )

        const deletedValues = assignedValue.filter(
            (x) => x.ActionType === ACTION_TYPES.DELETED,
        )

        const updatedValues = assignedValue.filter(
            (x) => x.ActionType === ACTION_TYPES.UPDATED,
        )

        const unChangedValues = assignedValue.filter(
            (x) => x.ActionType === ACTION_TYPES.NO_CHANGE,
        )

        const fixedValues = assignedValue.filter(
            (x) => x.ActionType === ACTION_TYPES.FIXED,
        )

        return (
            <Box
                sx={{
                    width: '100%',
                }}
            >
                {bindFreeTextValueNames(
                    'Common_Added',
                    addedValues,
                    updatedValues?.length ||
                        deletedValues?.length ||
                        unChangedValues?.length,
                )}
                {bindFreeTextValueNames(
                    'Common_Updated',
                    updatedValues,
                    addedValues?.length ||
                        deletedValues?.length ||
                        unChangedValues?.length,
                )}
                {bindFreeTextValueNames(
                    'Common_Deleted',
                    deletedValues,
                    updatedValues?.length ||
                        addedValues?.length ||
                        unChangedValues?.length,
                )}
                {bindFreeTextValueNames(
                    'Unchanged',
                    unChangedValues,
                    updatedValues?.length ||
                        deletedValues?.length ||
                        addedValues?.length,
                )}
                {fixedValues?.length ? (
                    <Box>
                        {fixedValues.map((item, index) => (
                            <Fragment key={`free-text-field-fixed-${index}`}>
                                {bindKeyValuePair(item)}
                            </Fragment>
                        ))}
                    </Box>
                ) : null}
            </Box>
        )
    }

    const bindFreeTextValueNames = (labelKey, values, showLabel) => {
        if (values?.length) {
            return (
                <Box>
                    {showLabel ? bindLabel(labelKey) : null}
                    {values.map((item, index) => (
                        <Fragment key={`free-text-field-unchanged-${index}`}>
                            {bindKeyValuePair(item)}
                        </Fragment>
                    ))}
                </Box>
            )
        } else {
            return null
        }
    }

    const bindLabel = (labelKey) => {
        return (
            <Box
                sx={{
                    '& label': {
                        color: '#9b9b9b',
                        textTransform: 'uppercase',
                        fontWeight: '600',
                        fontSize: '1.2rem',
                    },
                }}
            >
                <label>{`${t(labelKey)}:`}</label>{' '}
            </Box>
        )
    }

    const bindKeyValuePair = (item) => {
        return (
            <Box className={classes.freeTextMultiValue}>
                <Box
                    color="#3b454d"
                    fontSize="1.3rem"
                    sx={{
                        display: 'flex',
                        maxWidth: cardView ? '12rem' : '15rem',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <label>{`${t('Common_Key')}:`}</label>{' '}
                    <Box
                        sx={{
                            width: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Tooltip title={item?.FriendlyName}>
                            {item?.FriendlyName}
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    color="#3b454d"
                    fontSize="1.3rem"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: cardView ? '20rem' : '60rem',
                    }}
                >
                    <label>{`${t('Common_Value')}:`}</label>{' '}
                    <Box
                        sx={{
                            maxWidth: cardView ? '20rem' : '60rem',
                        }}
                    >
                        <Tooltip title={item?.ValueName}>
                            <Box>{item?.ValueName}</Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box {...rest}>
            <Box className={classes.container}>
                <Box
                    className={classNames(classes.containerWrapperMain, {
                        [classes.containerWrapperCardView]: cardView,
                    })}
                >
                    <Box
                        style={{
                            maxWidth: cardView ? '8rem' : '26rem',
                            width: '100%',
                        }}
                    >
                        <label
                            style={{
                                background: '#8b909a',
                                color: '#fff',
                                fontSize: '1.2rem',
                                padding: '0.4rem 0.8rem',
                                borderRadius: '0.4rem',
                                fontWeight: 'bold',
                            }}
                        >{`${t('Common_AppRight')}`}</label>
                    </Box>
                    <Box className={classes.fieldHead}>{item?.displayName}</Box>
                </Box>

                <Box
                    style={{
                        position: 'relative',
                        top: '0.7rem',
                    }}
                >
                    {!cardView && (
                        <Icon name="dynamicForm" height="4rem" width="4rem" />
                    )}
                </Box>
            </Box>

            {fieldTypeswithValue?.map((field, index) => (
                <Fragment key={`fieldtypes-${index}`}>
                    <Box
                        className={classNames(classes.rootWrapper, {
                            [classes.rootWrapperCardView]: cardView,
                        })}
                    >
                        {cardView ? (
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    className={classNames(classes.fieldName, {
                                        [classes.fieldNameCardView]: cardView,
                                    })}
                                >
                                    {`${field?.Name}`}
                                    <span
                                        className={classes.fieldScopeName}
                                    >{`(${field?.FieldTypeScope})`}</span>
                                </Box>
                                {isFieldModified(field) && (
                                    <Box
                                        className={
                                            classes.modifiedFieldLabelCardView
                                        }
                                    >
                                        <StatusIndicator
                                            label={t(getChangeLabel(field))}
                                            size="small"
                                            variant={getChangeLabelVariant(
                                                field,
                                            )}
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box
                                style={{
                                    display: 'flex',
                                    maxWidth: '24rem',
                                    width: '100%',
                                }}
                            >
                                <Box className={classes.fieldName}>
                                    {`${field?.Name}`}
                                    <span
                                        className={classes.fieldScopeName}
                                    >{`(${field?.FieldTypeScope})`}</span>
                                </Box>
                            </Box>
                        )}

                        <Box
                            className={classNames(classes.fieldData, {
                                [classes.fieldDataRange]:
                                    field?.SelectionRuleType === 'Range',
                            })}
                        >
                            {field?.SelectionRuleType === 'Range' ? (
                                <Fragment>
                                    {field?.SelectionRule ===
                                        FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE ||
                                    field?.SelectionRule ===
                                        FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ? (
                                        bindRangeValue(field)
                                    ) : (
                                        <Fragment>
                                            <Box fontSize="1.3rem">
                                                {bindFixedValue(field)}
                                            </Box>
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE ||
                              field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.FIXED_LIST ||
                              field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.PERSON_RELATIVE ? (
                                <>
                                    {bindFreeTextMultiValue(
                                        field?.[getFieldValueType(field)],
                                    )}
                                </>
                            ) : (
                                <Fragment>
                                    {typeof field?.[
                                        getFieldValueType(field)
                                    ] === 'string' ? (
                                        <Box fontSize="1.3rem">
                                            {field?.ActionType === 'Deleted'
                                                ? field?.oldValue?.oldValue
                                                : field?.[
                                                      getFieldValueType(field)
                                                  ]?.ValueName}
                                        </Box>
                                    ) : Array.isArray(
                                          field?.[getFieldValueType(field)],
                                      ) ? (
                                        <Fragment>
                                            {bindArrayValues(
                                                field?.[
                                                    getFieldValueType(field)
                                                ],
                                                field,
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Box fontSize="1.3rem">
                                            {field?.ActionType === 'Deleted'
                                                ? field?.oldValue?.oldValue
                                                : field?.[
                                                      getFieldValueType(field)
                                                  ]?.FriendlyName ||
                                                  field?.[
                                                      getFieldValueType(field)
                                                  ]?.ValueName}
                                        </Box>
                                    )}
                                </Fragment>
                            )}
                        </Box>
                        {isFieldModified(field) && !cardView && (
                            <Box className={classes.modifiedFieldLabel}>
                                <StatusIndicator
                                    label={t(getChangeLabel(field))}
                                    size="small"
                                    variant={getChangeLabelVariant(field)}
                                />
                            </Box>
                        )}
                    </Box>
                    {!isLastItem && <Divider />}
                    {isLastItem && index !== item?.fieldTypes?.length - 1 && (
                        <Divider />
                    )}
                </Fragment>
            ))}
        </Box>
    )
}

export default RequestDynamicFieldTypeValues
